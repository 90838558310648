<template>
  <v-menu
    v-model="menuDateInicial"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px">

        <template v-slot:activator="{ on }">
        <v-text-field
            v-model="dtInicialString"
            v-mask="['##/##/####']"
            :label="$gettext(label)"
            @input="testeDate('inicial', dtInicialString)"
            dense
            :outlined="propOutlined"
            :error-messages="propErrorMessages"
            v-on="on"
            :disabled="disabled" >
            <template v-slot:append>
            <v-icon v-on="on">
                event
            </v-icon>
            </template>
        </v-text-field>
        </template>

    <v-date-picker
        v-model="dtInicial"
        no-title
        scrollable
        locale="pt-br"
        @input="closeModalDatePicker('inicial', dtInicial)"
        :color="variables.colorPrimary"
        :disabled="disabled" >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="clearDateFilter('inicial', dtInicial)">Limpar</v-btn>
        <v-btn text color="primary" @click="closeModalDatePicker('inicial', dtInicial)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
import { mask } from 'vue-the-mask'

export default {
	props: {
    label: {
      type: String,
      default: 'Data'
    },
    propOutlined: {
      type: Boolean,
      default: false
    },
    propErrorMessages: {
      type: Array,
      default: (() => [])
    },
    isDateInit: {
      type: Boolean,
      default: true
    },
    dateInit: {
      type: String,
      default: moment().format('YYYY-MM-DD')
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
	name: 'InPutDate',
	directives: { mask },
	data () {
		return {
			dtInicial: '',
			dtInicialString:'',
			menuDateInicial: false
		}
	},
	mounted () {
    const vm = this
    vm.dtInicialString = this.isDateInit ? moment(this.dateInit).format('DD/MM/YYYY') : ''
    vm.dtInicial = this.isDateInit ? moment(this.dateInit).format('YYYY-MM-DD') : ''
	},
	watch: {
		dtInicial (v) {
			this.$emit('update:date', v)
		}
	},
	computed: {
		variables: () => variables,
	},
	methods: {
		testeDate (dt, dtString) {
      const testeDate =  moment(dtString, 'DD/MM/YYYY', true)
      if (testeDate.isValid()) {
        switch (dt) {
          case 'inicial':
            this.dtInicial = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
            return
          case 'final':
            this.dtFinal = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
            return
        }
      }
    },
    closeModalDatePicker (dt, date) {
      switch (dt) {
        case 'inicial':
          this.dtInicialString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          this.menuDateInicial = false
          return
        case 'final':
          this.dtFinalString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          this.menuDateFinal = false
          return
      }
    }
	}
}
</script>